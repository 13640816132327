import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Loading from "@/components/widgets/Loading.vue"
import Vue from "vue"
import Drawer from '@/components/drawers'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      inputType: 'file',
      fileError: '',
      file: null,
      form: {
        image: null,
        code: null,
        type: null,
        name: null,
        description: null,
        unit_name: null,
        qualtity: null,
        status: 1,
        cost: null,
        price: null,
        note: null,
      },
    }
  },
  validations: {
    form: {
      code: {  },
      type: {  },
      name: { required },
      description: { },
      unit_name: {  },
      qualtity: { },
      status: { },
      cost: { },
      price: { },
      note: { },
    },
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n

        if (this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  methods: {
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    resetForm () {
      this.form.image = null
      this.form.code = null
      this.form.type = null
      this.form.name = null
      this.form.description = null
      this.form.unit_name = null
      this.form.qualtity = null
      this.form.status = 1
      this.form.cost = null
      this.form.price = null
      this.form.note = null

      this.$nextTick(() => {
        this.$v.$reset()
      })

      this.onResetFileInput()
    },
    formBuilder(obj) {
      this.form.code = obj.data?.code ?? null
      this.form.type = obj.data?.type ?? null
      this.form.name = obj.data?.name ?? null
      this.form.description = obj.data?.description ?? null
      this.form.unit_name = obj.data?.unit_name ?? null
      this.form.qualtity = obj.data?.qualtity ?? null
      this.form.status = obj.data?.status ?? null
      this.form.cost = obj.data?.cost ?? null
      this.form.price = obj.data?.price ?? null
      this.form.note = obj.data?.note ?? null

      // this.$v.form.$touch()
    },
    onFileHandler(e) {
      this.$refs.fileInput.click()
    },
    onResetFileInput() {
      this.inputType = 'text'      
      this.$nextTick(() => {
        this.fileError = ''
        this.file = null
        this.inputType = 'file'    
      })
    },
    onFileChange(e) {
      if (e.target.files.length > 0) { 
        this.fileError = ''
        const selectedFile = e.target.files[0]
        // File type validation (accept only image files)
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if (!allowedFileTypes.includes(selectedFile.type)) {
          this.fileError = 'ไฟล์รูปภาพไม่ถูกต้อง. กรุณาเลิอกไฟล์รูปภาพ.'
          return;
        } 
        
        this.onFIletoBase64(selectedFile).then(file => {
          this.file = file
          this.form.image = file
        }).catch(error => {
          this.onExceptionHandler(error.message)
        })
      }      
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onInitData() {
      try {
        this.loading = true

        const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/miscellaneous/${this.id}`)
        this.loading = false
        if (result.status === 200) {

          this.formBuilder(result)
        }
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        if (this.form.image) {
          formData.append('image', this.form.image)
        }
        formData.append('code', this.form.code)
        formData.append('type', this.form.type)
        formData.append('name', this.form.name)
        formData.append('description', this.form.description)
        formData.append('unit_name', this.form.unit_name)
        formData.append('qualtity', this.form.qualtity)
        formData.append('status', this.form.status)
        formData.append('cost', this.form.cost)
        formData.append('price', this.form.price)
        formData.append('note', this.form.note)

        let result = {}
        if (this.mode === 'create') {
          result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/miscellaneous`, formData)
        } else {
          result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/miscellaneous/${this.id}`, formData)
        }
        this.loading = false
        setTimeout(() => {
          if (result.status === 201 || result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.onCloseDrawer()
            })
          }
        }, 500)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    }
  }
}
